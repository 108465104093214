// Educator-led admin interface
//
// This layout lives in the admin, designed for Educators to build out the timing
// structure of games. For the time being, this is desktop-only.
//
// Markup: ../../styleguide/src/components/educator.hbs
//
// chromeless: true
//
// full: true
//
// Weight: 60
//
// Style guide: educator

:root {
  --edu-header-height: var(--site-nav-height);
}

.edu-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 80px;
  height: var(--edu-header-height);
  background: var(--body-bg-color);
  z-index: $z-index-sticky-header;
  border-bottom: 1px solid var(--brand-color-tint4);

  &-title,
  &-subtitle,
  &-aside-text {
    margin: 0;
  }

  &-title {
    font-size: var(--font-size-small);
    color: var(--text-color-subdued);
    font-weight: var(--global-weight-semibold);
  }

  &-aside-text {
    text-transform: uppercase;
    font-weight: var(--global-weight-semibold);
  }

  .walkthrough-grid {
    height: 100%;
  }
}

.is-below-edu-header {
  padding-top: var(--edu-header-height);
}



.edu-pad-bleed-right {
  margin-right: rem-calc(-20);

  &.table-scroll {
    max-width: none;
  }
}

.edu-r-pad,
.edu-l-pad,
.edu-h-pad {
  padding-right: rem-calc(20);
  padding-left: rem-calc(20);
}

@include breakpoint($nav-breakpoint) {
  .edu-h-pad {
    padding-right: rem-calc(40);
    padding-left: rem-calc(40);

    &.clamp-w-large {
      max-width: 1100px;
    }
  }

  .edu-pad-bleed-right {
    margin-right: rem-calc(-40);
  }

  .edu-r-pad {
    padding-right: rem-calc(40);
    padding-left: 0;
  }

  .edu-l-pad {
    padding-left: rem-calc(40);
    padding-right: 0;
  }
}

.walkthrough {
  &-grid {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    max-width: 1200px;

    &.not-reversed {
      flex-direction: column;
    }

    &.stretch-cards {
      .card {
        flex: 1 1 100%;
      }
    }
  }

  &-main {
    grid-area: main;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .page-subheading {
      margin-top: 0;
    }

    .card + .card {
      margin-top: 2rem;
    }
  }

  &-aside {
    grid-area: aside;

    &.is-shade {
      background: var(--brand-color-tint4);
    }
  }

  &-heading {
    font-weight: var(--global-weight-semibold);
    margin-bottom: 0;
    font-size: rem-calc(16);
  }

  &-help-heading {
    --icon-size: #{rem-calc(20)};
    display: flex;
    align-items: flex-start;
    font-weight: var(--global-weight-semibold);
    margin-bottom: rem-calc(8);

    .icon {
      margin-right: rem-calc(6);
    }
  }

  &-subheading,
  &-help-text {
    font-size: var(--font-size-small);
  }

  &-help-heading,
  &-help-text {
    max-width: 480px;
  }

  &-help-text {
    padding-left: 26px;
  }

  &-fieldset {
    margin-bottom: rem-calc(60);
  }

  &-subdued-heading {
    font-weight: var(--global-weight-semibold);
    font-size: rem-calc(14);
    color: var(--text-color-subdued);
  }

  &-chapter-heading {
    font-weight: var(--global-weight-semibold);
    margin-bottom: 1rem;
    font-size: rem-calc(20);
  }

  .is-v-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .is-h-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card {
    @include bottomless;
  }

  @include breakpoint($nav-breakpoint) {
    &-grid {
      display: grid;
      grid-gap: 0 rem-calc(30);
      grid-template-columns: 5fr 4fr;
      grid-template-areas: "main aside";
    }
  }
}

.event-triggers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 340px;

  > * {
    margin: 0;
  }
}

.synchronized-chapter-card {
  padding: 0;

  .walkthrough-aside,
  .walkthrough-main {
    padding: rem-calc(20);

    &.pb-0 {
      padding-bottom: 0;
    }
  }
}

.synchronized-chapter-item {
  &.is-selected {
    // padding: rem-calc(20) rem-calc(40);
    margin-left: -10px;
    margin-right: -10px;
    box-shadow: var(--base-shadow);
    background: var(--card-bg);
    border-radius: var(--global-radius);
    border: 1px solid var(--card-border-color-default);
  }

  .walkthrough-aside {
    background: var(--info-color-alpha-7);
  }
}

.edu-header-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  input {
    min-width: 240px;
  }

  .button-wrap {
    flex: 0 0 auto;
    flex-wrap: nowrap;
  }
}

.player {
  &-col {
    padding-bottom: rem-calc(20);
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;

    p {
      margin: 0;
    }

    a {
      color: var(--alert-color);
      text-decoration: none;
      font-size: var(--font-size-small);
      font-weight: var(--global-weight-semibold);

      &:hover {
        color: var(--alert-color-shade);
        text-decoration: underline;
      }
    }
  }

  &-card {
    padding: 0;

    & + & {
      margin-top: 2rem;
    }
  }

  &-card-heading {
    color: var(--knockout-color);
    font-size: rem-calc(18);
    margin: 0;
  }

  &-card-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-card-summary,
  &-email {
    font-size: var(--font-size-small);
  }

  &-item {
    --avatar-size: #{rem-calc(36)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem-calc(8);
    border-bottom: 1px solid var(--card-border-color-default);

    p,
    .field {
      margin: 0;
    }

    &.is-selected {
      background-color: var(--info-color-tint5);

      .avatar:before {
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        background-image: svg-url('<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><circle cx="18" cy="18" r="18" fill="#9EC2E3" /><path fill="#fff" d="M11.5 11.5h13v13.772h-13z"/><path d="M13 18.555l4.118 3.5 5.882-9" stroke="#2e373f" stroke-width="3" stroke-linejoin="round"/></svg>');
        content: '';
      }
    }

    &:not(.is-selected):hover {
      background-color: var(--info-color-tint5);

      .avatar:before {
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        background-image: svg-url('<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><circle cx="18" cy="18" r="18" fill="#9EC2E3"/><path fill="#fff" d="M11.5 11.5h13v13.772h-13z"/></svg>');
        content: '';
      }
    }

    .avatar-detail-meta {
      font-weight: var(--global-weight-semibold);
      font-size: var(--font-size-small);
    }

    &.is-header {
      color: var(--knockout-color);
      border-radius: var(--global-radius-tl) var(--global-radius-tr) 0 0;
      display: grid;
      grid-gap: 0 rem-calc(8);
      grid-template-columns: 3fr 4fr rem-calc(32);
      min-height: 64px;
    }

    @at-root .player-list & {
      grid-template-columns: 1fr 3fr rem-calc(96);
    }
  }

  &-card-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @include breakpoint($nav-breakpoint) {
    &-grid {
      display: grid;
      grid-gap: 0 rem-calc(60);
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      padding-bottom: rem-calc(60);
    }

    &-item {
      padding-right: rem-calc(16);

      &.is-header {
        padding: rem-calc(16);
      }
    }
  }

  @include breakpoint($small-only) {
    &-item {
      .button {
        padding-left: rem-calc(4);
        padding-right: rem-calc(4);
        margin-left: rem-calc(8);
      }
    }
  }
}

.settings-button,
.toggle-button {
  --icon-size: #{rem-calc(20)};
  appearance: none;
  border: 0 none;
  background: transparent;
  color: var(--knockout-color);
  padding: 0;
  margin: 0;
  border-right: 1px solid var(--tint-alpha-30);
  display: flex;
  align-items: center;
}

.settings-button {
  padding: 0;

  &:before {
    right: 15px;
  }

  &:after {
    content: none;
  }
}

.toggle-button {
  --icon-size: #{rem-calc(20)};
  border-right: 0 none;

  &.is-expanded {
    transform: rotate(180deg);
  }
}

.player-toggle {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li:not(:last-child) {
    &:after {
      color: var(--text-color-subdued);
      content: "/";
      display: inline-block;
      margin: 0 8px;
    }
  }

  a {
    display: inline-block;
    border-radius: var(--global-radius);

    &[aria-selected="true"] {
      background: var(--info-color-shade);
      color: var(--knockout-color);
      padding: 0 8px;
    }
  }
}

.all-players-managed {
  --icon-color: var(--info-color-tint2);
  --icon-size: 64px;
  background-color: var(--info-color-tint5);
  padding: rem-calc(60) rem-calc(20);
  border-radius: var(--global-radius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    margin-bottom: 1rem;
  }
}

.chart-filters {
  max-width: 600px;
}

.run-section {
  margin: rem-calc(20) 0;

  .card {
    margin-bottom: 1rem;
  }

  @include breakpoint($nav-breakpoint) {
    margin: rem-calc(42) 0;
  }
}

// Educator-led review
//
//
// Markup: ../../styleguide/src/components/educator-review.hbs
//
// chromeless: true
//
// full: true
//
// Weight: 61
//
// Style guide: educator.review

// Educator-led configure events
//
//
// Markup: ../../styleguide/src/components/educator-events.hbs
//
// chromeless: true
//
// full: true
//
// Weight: 62
//
// Style guide: educator.events

// Educator-led Leaderboard
//
//
// Markup: ../../styleguide/src/components/educator-leaderboard.hbs
//
// chromeless: true
//
// full: true
//
// Weight: 63
//
// Style guide: educator.leaderboard

// Educator-led Players
//
// Markup: ../../styleguide/src/components/educator-players.hbs
//
// chromeless: true
//
// full: true
//
// Weight: 64
//
// Style guide: educator.players

// Educator-led Progress
//
// Markup: ../../styleguide/src/components/educator-progress.hbs
//
// chromeless: true
//
// full: true
//
// Weight: 65
//
// Style guide: educator.progress

// Educator-led Reports
//
// Markup: ../../styleguide/src/components/educator-reports.hbs
//
// chromeless: true
//
// full: true
//
// Weight: 66
//
// Style guide: educator.reports

// Educator-led Timing
//
// Markup: ../../styleguide/src/components/educator-timing.hbs
//
// chromeless: true
//
// full: true
//
// Weight: 66
//
// Style guide: educator.timing

.survey-container {
  max-width: 960px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
}

.survey-intro-text {
  font-size: rem-calc(18);
  max-width: 760px;
  margin: 1rem 0;
}

.results-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
}

.student-list {
  display: block;
  list-style: none;
  margin: 1rem 0;
  padding: 0;
}

.student-meta-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: var(--font-size-small);
}

  // \  <ul class="student-list">
  //     <li class="student-item">
  //       <div class="student-info">
  //         <p class="student-name" title="Created: 24 June 2021, 10:31a EST">
  //           John Lennon
  //         </p>
  //         <ul class="student-meta-list">
  //           <li class="student-id"><span class="student-id-label">ID:</span> 00024</li>
  //           <li><a class="student-email" href="mailto:test@test.com">john@test.com</a></li>
  //           <li><a href="#TODO" class="student-link">https://testurl.com/example-run#HashKey</a></li>
  //         </ul>
  //       </div>
  //
  //       <span class="student-status">

.student-item {
  display: flex;
  grid-gap: 10px;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 0.5rem 0;
}

.student-name {
  margin-bottom: 0;
}

.student-id-label {
  font-size: 0.7em;
  opacity: 0.6;
}

.student-status {
  color: var(--text-color-subdued);
  display: flex;
  align-items: center;
  gap: 4px;

  &.is-complete {
    color: var(--success-color);
  }

  &-label {
    font-size: 12px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.results-section {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.skinny-container {
  max-width: 480px;
  margin: 0 auto;
}

.chart-filter-header {
  margin-bottom: 2rem;
}
.chart-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.planner-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 2fr 1fr;

  .button {
    flex: 1 1 auto;
    max-width: calc(100% - 2rem - 114px);
  }

  .value {
    float: right;
  }
}

.assumptions > li {
  margin: 1rem 0;

  > .value {
    font-weight: var(--global-weight-semibold);
  }
}

.assumption-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  list-style: none;
  margin: 1rem 0;
  padding: 0;
}

.assumption-item {
  flex: 0 1 calc(50% - 0.5rem);
  margin: 0;
  padding: 0 0.5rem 0.25rem;
  background: var(--success-color-tint5);
  border-radius: var(--global-radius);

  p {
    margin: 0;
  }
}

.assumption-label {
  font-size: 0.8em;
}

.final-charts {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}
