@use "sass:math";

@import "breakpoint-sass/stylesheets/breakpoint";
@import 'choices.js/src/styles/choices.scss';

@import "base/fonts";
@import "base/mixins";

@import "base/project-theme";

:root {
  --body-bg-image: none;
  --field-border-color: #ddd;
  --mobile-sub-nav-expanded-bg: #fff;
}

@import "base/colors";

@import "base/theme-classes";
@include theme-classes($colors);

@import "base/variables";
@import "base/utilities";

@import "base/normalize";
@import "base/typography";
@import "base/forms";
@import "base/buttons";
@import "base/tables";

@import "module/avatar";
@import "module/avatar-details";
@import "module/breadcrumbs";
@import "module/card-base";
@import "module/dropdowns";
@import "module/highcharts";
@import "module/icons";
@import "module/note";
@import "module/progress";
@import "module/simpl-header";
@import "module/toggles";
@import "module/vertical-nav";
@import "module/wells";

@import "layout/base-page";
@import "layout/educator";
@import "layout/todo-aside";
