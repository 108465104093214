// Breadcrumbs
//
// Breadcrumbs are used throughout the system as a navigation option.
//
// Markup: ../../styleguide/src/components/breadcrumb.hbs
//
// Weight: 4
//
// Style guide: Navigation.breadcrumbs

.breadcrumb {
  display: block;
  font-size: rem-calc(13);
  margin-top: -1rem;
  margin-bottom: 0.5rem;

  &-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: rem-calc(6) 0 rem-calc(3);
    padding: 0;
  }

  &-current {
    display: none;
  }

  &-link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    &:before {
      display: inline-block;
      padding: 0 10px;
      opacity: 0.5;
      content: '‹';
    }

    &:hover {
      .breadcrumb-label {
        text-decoration: underline;
        color: var(--dark-bunting);
      }
    }
  }

  &-icon {
    --icon-size: #{rem-calc(20)};
    margin-right: rem-calc(6);
  }

  @include breakpoint($nav-breakpoint) {

    .breadcrumb-current {
      display: inline-flex;
      align-items: center;
      font-weight: var(--global-weight-semibold);
    }

    .breadcrumb-link {

      &:before {
        content: none;
      }

      &:after {
        display: inline-block;
        padding: 0 10px;
        opacity: 0.5;
        content: '›';
      }
    }

  }
}
