:root {
  --todo-aside-width: 300px;
}

.todo-heading {
  font-size: rem-calc(24);
  font-weight: var(--global-weight-normal);
  color: var(--text-color);
  margin-bottom: var(--global-margin);
}
.todo-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.todo-link {
  position: relative;
  font-size: rem-calc(16);
  text-decoration: none;
  display: block;
  padding: 0.5rem var(--global-margin) 0.5rem calc(var(--global-margin) + 30px);
  margin: 0 calc(-1 * var(--global-margin));
  color: var(--link-color);

  &:not([disabled]):hover {
    background: rgba(255,255,255, 0.5);
  }

  &:before {
    display: block;
    position: absolute;
    top: 10px;
    left: 1.25rem;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--brand-color-alpha);
    mask-position: center left;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    content: "";
  }

  &.is-complete:before,
  &.is-active.is-complete:before,
  &.is-active.is-complete:hover:before {
    mask-image: svg-url('<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><path d="M11 0C4.926 0 0 4.926 0 11c0 6.078 4.926 11 11 11 6.076 0 11-4.924 11-11 0-6.074-4.924-11-11-11zm4.934 9.357c-.014.016-.025.03-.04.044l-2.261 2.258a.293.293 0 01-.04.035l-.219.219-.018.018-.015.018-1.97 1.966a.092.092 0 01-.018.019l-.743.743a.93.93 0 01-1.289-.016l-.02-.02-2.588-2.588a1.242 1.242 0 01.029-1.736 1.246 1.246 0 011.766 0l1.478 1.479 4.13-4.13c.23-.214.532-.333.846-.333a1.242 1.242 0 01.972 2.024z" fill="#144984"/></svg>');
    width: 20px;
    height: 20px;
    border: 0 none;
    background-color: var(--success-color);
  }

  &.is-active,
  &.is-active:hover {
    background: var(--tint);
  }

  &.is-active:not(.is-complete):not(.is-alert):before {
    background-color: var(--tint);
    border: 8px solid var(--info-color);
    width: 4px;
    height: 4px;
  }

  &.is-alert:before {
    mask-image: svg-url('<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49"><path d="M24.35.509a24 24 0 1024 24 24.027 24.027 0 00-24-24zm-2.572 12a2.571 2.571 0 115.143 0v10.285a2.572 2.572 0 01-5.143 0V12.508zm2.571 25.714a3.429 3.429 0 110-6.858 3.429 3.429 0 010 6.858z" fill="#000"/></svg>');
    background-color: var(--caution-color);
    content: "";
  }

  &.is-alert[disabled]:before {
    background-color: var(--brand-color-alpha);
  }

  &[disabled] {
    cursor: not-allowed;

    .todo-link-title {
      opacity: 0.3;
    }
  }
}

.todo-count {
  background: var(--info-color-tint4);
  border-radius: var(--global-radius);
  padding: 0 6px;
  margin-right: 4px;
}

.todo-help-text {
  display: flex;
  align-items: center;
  font-size: var(--font-size-small);
  color: var(--text-color);
}

.todo-aside-layout {
  display: flex;
  flex-direction: column-reverse;
}

.todo-aside {
  border-radius: var(--global-radius-tl) var(--global-radius-tr) 0 0;
  box-shadow: var(--aside-shadow);
  background: var(--info-color-tint5);
  padding: var(--global-margin);
}


@include breakpoint($nav-breakpoint) {
  .todo-aside-layout {
    display: block;
    padding-right: var(--todo-aside-width);

    .page-header {
      margin-left: rem-calc(-40);
      padding-left: rem-calc(40);

      margin-right: rem-calc(-40);
      padding-right: rem-calc(40);

      margin-right: calc(-1 * (var(--todo-aside-width) + #{rem-calc(40)}));
      padding-right: calc(var(--todo-aside-width) + #{rem-calc(40)});
    }
  }

  .todo-main {
    grid-area: main;
  }

  .todo-aside {
    position: fixed;
    top: 16px;
    right: rem-calc(40);
    width: calc(var(--todo-aside-width) - 40px);
    min-height: calc(100vh - 16px);
    z-index: 11;

    &.is-small {
      min-height: 0;
      margin-bottom: auto;
    }
  }
}
