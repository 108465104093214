// Forms
//
// Forms are used throughout the system and can be arranged in several ways. The
// following is an example of how fields, field rows, helper text, fieldsets,
// toggles, and button rows can be combined to create a good user experience.
// Scroll down this page for more detailed documentation for each of the
// elements demonstrated here.
//
// Markup: ../../styleguide/src/components/forms.hbs
//
// Weight: 5
//
// chromeless: true
//
// full: true
//
// Style guide: forms

:root {
  --field-background: var(--knockout-color);
  --field-border-color: var(--text-color-subdued);
  --select-border-color: var(--text-color-subdued);
  --field-focus-border-color: var(--brand-color);
  --field-focus-background: var(--knockout-color);
  --field-radius: var(--global-radius);
  --field-font-size: #{rem-calc(14)};
  --label-font-size: #{rem-calc(14)};
  --error-color: var(--alert-color);
  --radio-size: #{rem-calc(18)};
  --max-field-width: #{rem-calc(400)};
  --focus-shadow-color: #{rgba(color(info-color), 0.4)};
  --focus-shadow: 0 0 0 3px var(--focus-shadow-color);
  --error-shadow: 0 0 0 3px var(--alert-color-tint);
  --checkbox-radio-color: var(--brand-color);
  --checkbox-radio-selected-color: var(--brand-color-shade);
}

// Input fields
//
// Help text can be added under the field with `<p class="help-text">` Help text
// should be be tied to the form field using the aria-describedby attribute and
// an ID. This will ensure that screen readers—will announce the help text when
// the user focuses the field.
//
// .error     - Indiciates a field error.
// .required - Indicates required state
// .disabled  - Indicates a disabled field. `disabled` should also be applied as an attribute to the field itself.
// .m-input - Shortens the width of the input field to 120px. Useful for small inline forms.
// .sm-input - Shortens the width of the input field to 80px. Useful for small inline forms.
// .xs-input - Shortens the width of the input field to 60px.
// .full - Removes the default max-width on field components.
// .medium-label - Gives the label the appearance of a paragraph.
// .large-label - Gives the label the appearance of a subheading.
// .inline-label - Make labels and fields appear side-by-side.
// .inline-label.reverse-label - On `.inline-label` elements, add this modifier to reverse placement of labels.
// .has-focus - Indicates a :focus state.
// .dark-fields - Gives field a darker appearance. To be used on white backgrounds.
//
// Markup: ../../styleguide/src/components/field.hbs
//
// Weight: 1
//
// Style guide: forms.input-fields

label:not(.button),
span.label {
  display: block;
  margin-bottom: 4px;
  font-size: var(--label-font-size);
}

.field,
.django-as-p > p {
  position: relative;
  margin-bottom: var(--global-margin);
  max-width: var(--max-field-width);

  &.error {
    --field-border-color: var(--error-border-color, var(--error-color));
    --select-border-color: var(--error-border-color, var(--error-color));

    label:not(.file-label-button),
    [type='checkbox'] + label .values:before {
      color: var(--error-color);
    }

    &.toggle label:before {
      background-color: var(--error-color);
    }

    .error-message {
      display: block;
    }

    .checkbox [type='checkbox'] + label:before {
      border-color: var(--error-color);
    }

    &.checkbox label:before,
    &.radio label:before,
    &.toggle label:before,
    input:not([type='submit']):not([type='checkbox']):not([type='radio']),
    select,
    textarea,
    .upload-preview {
      box-shadow: var(--error-shadow);
    }
  }

  &.required {
    label {
      font-weight: var(--global-weight-semibold);

      &:after {
        content: '*';
      }
    }
  }

  &:not(.toggle):focus-within,
  &.has-focus {
    &.checkbox label:before,
    label.is-checked:before,
    &.radio label:before,
    &.toggle label:before {
      box-shadow: var(--focus-shadow);
    }

    [type='checkbox']:checked + label:after,
    [type='radio'] + label:after {
      position: absolute;
      top: 4px;
      left: 1px;
      z-index: 0;
      width: 14px;
      height: 13px;
      box-shadow: var(--focus-shadow);
      content: '';
    }

    [type='radio'] + label:after {
      top: 2px;
      left: 2px;
      width: calc(var(--radio-size) - 4px);
      height: calc(var(--radio-size) - 4px);
      border-radius: 50%;
    }
  }

  [type='checkbox']:invalid:focus + label:after,
  [type='radio']:invalid:focus + label:after {
    position: absolute;
    top: 4px;
    left: 1px;
    z-index: 0;
    width: 14px;
    height: 13px;
    box-shadow: var(--error-shadow);
    content: '';
  }

  [type='radio']:invalid:focus + label:after {
    top: 2px;
    left: 2px;
    width: calc(var(--radio-size) - 4px);
    height: calc(var(--radio-size) - 4px);
    border-radius: 50%;
  }

  &.no-text {
    &.checkbox label {
      margin-bottom: 0;
      height: 16px;
      vertical-align: top;

      &:before {
        margin: 0;
      }
    }
  }

  &.inline-label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    label {
      margin-right: rem-calc(10);
      width: 240px;
    }

    select {
      width: 300px;
      max-width: 100%;
    }

    &.reverse-label {
      label {
        order: 2;
        margin-left: 10px;
      }
    }

    .error-message {
      order: 3;
      width: 100%;
    }
  }

  &.full {
    max-width: none;
  }

  &.large-label {
    label {
      font-size: rem-calc(18);
      font-weight: var(--global-weight-semibold);
    }
  }

  &.medium-label {
    label {
      font-size: var(--font-size-base);
    }
  }
}

.dark-fields {
  --field-background: var(--dark-field-bg, var(--body-bg-shade-half));
  --field-border-color: var(--dark-field-border-color, var(--field-background));
}

.disabled label,
.disabled input,
.disabled button,
.disabled select,
.disabled textarea,
.disabled .help-text,
*:disabled,
*:disabled ~ label {
  @include disabled;
}

input:not([type='submit']):not([type='checkbox']):not([type='radio']):not([type='file']),
select,
textarea {
  appearance: none;
  box-sizing: border-box;
  padding: rem-calc(10);
  width: 100%;
  min-height: 38px;
  box-shadow: none;
  border: 1px solid var(--field-border-color);
  border-width: var(--field-border-width, 1px);
  border-radius: var(--field-radius);
  background-color: var(--field-background);
  font-family: var(--field-font-family, var(--global-font-family));
  font-size: var(--field-font-size);
  color: var(--field-color, var(--text-color));
  letter-spacing: var(--field-letter-spacing, normal);

  &:focus,
  .has-focus & {
    box-shadow: var(--focus-shadow);
    border-color: var(--field-focus-border-color);
    background-color: var(--field-focus-background);
    outline: none;
  }

  &:invalid:focus {
    --field-focus-border-color: var(--error-color);
    --select-border-color: var(--error-color);
    box-shadow: var(--error-shadow);
  }

  &[required] + label {
    &:after {
      content: '*';
    }
  }

  .m-input &,
  &.m-input {
    width: 120px;
    margin-left: 8px;
  }

  .sm-input &,
  &.sm-input {
    width: 80px;
  }

  .xs-input &,
  &.xs-input {
    width: 60px;
  }
}

.field.has-button {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  label {
    width: 100%;
  }

  input:not([type='submit']):not([type='checkbox']):not([type='radio']):not([type='file']),
  select,
  textarea {
    flex: 1 1 auto;
    max-width: rem-calc(260);
  }

  .button {
    margin-left: rem-calc(8);
  }
}

// clears out styles and matches site font.
input[type='file'] {
  appearance: none;
  width: 100%;
  border-color: transparent;
  font-size: var(--field-font-size);
}

// File field input
//
// This is an optional enhancement to style file inputs. For more information on
// the method used to achieve this effect, [see this blog post](https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/).
//
// Markup: ../../styleguide/src/components/field_file.hbs
//
// Weight: 20
//
// Style guide: forms.file-inputs

.hide-file-input {
  position: absolute;
  z-index: -1;
  width: 0.1px; // zero here would break the tab order.
  height: 0.1px;
  overflow: hidden;
  opacity: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

legend {
  margin: 0;
  padding: 1em 0 0.5em; // margin was collapsing strangely here.
  font-size: rem-calc(24);
  font-weight: var(--global-weight-semibold);
  color: var(--primary-heading-color);
}

// Select
//
// .error     - Indiciates a field error.
// .required - Indicates required state
// .disabled  - Indicates a disabled field. `disabled` should also be applied as an attribute to the field itself.
// .has-focus - Indicates a :focus state.
//
// Markup: ../../styleguide/src/components/field_select.hbs
//
// Weight: 2
//
// Style guide: forms.select

select {
  position: relative;
  padding-right: rem-calc(40);
  border-color: var(--select-border-color);
  background-image: #{svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 10"><path fill="none" vector-effect="non-scaling-stroke" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M1 1l8 8 8-8"/></svg>')};
  background-color: transparent;
  background-position: top 14px right 14px;
  background-repeat: no-repeat;
  background-size: 12px 12px;

  &[multiple] {
    background-image: none;
    padding-right: rem-calc(10);
  }

  option {
    background-color: var(--select-option-bg-color, var(--body-bg-color));
  }
}

// Textarea
//
// .error     - Indiciates a field error.
// .required - Indicates required state
// .disabled  - Indicates a disabled field. `disabled` should also be applied as an attribute to the field itself.
// .has-focus - Indicates a :focus state.
//
// Markup: ../../styleguide/src/components/field_textarea.hbs
//
// Weight: 3
//
// Style guide: forms.textarea

textarea {
  display: block;
  min-height: rem-calc(90);
  font-family: var(--textarea-font-family, var(--global-font-family));
  letter-spacing: 0;
}

// Checkboxes
//
// .error     - Indiciates a field error.
// .required - Indicates required state
// .disabled  - Indicates a disabled field. `disabled` should also be applied as an attribute to the field itself.
// .has-focus - Indicates a :focus state.
//
// Markup: ../../styleguide/src/components/field_checkbox.hbs
//
// Weight: 4
//
// Style guide: forms.input-checkboxes

.checkbox {
  [type='checkbox'] {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  label:before {
    @include square(14px);
    display: inline-block;
    margin-right: 6px;
    margin-bottom: -3px;
    border: 1px solid var(--checkbox-radio-color);
    vertical-align: baseline;
    content: '';
  }

  [type='checkbox']:checked + label:before,
  label.is-checked:before {
    // background, checked state
    @include square(16px);
    z-index: 1;
    border: none;
    background-color: var(--checkbox-radio-selected-color);
    // mask-image: url('#{$sprite-path}#checkbox_selected');
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: 18px 18px;
  }

  &.checkbox-only {
    label {
      @include square(16px);
      display: block;
      margin: 0;
    }
  }
}

.radio-group,
.checkbox-group {
  .field:not(:last-child) {
    margin-bottom: rem-calc(10);
  }
}

// Radio Buttons
//
// .error     - Indiciates a field error.
// .disabled  - Indicates a disabled field. `disabled` should also be applied as an attribute to the field itself.
// .has-focus - Indicates a :focus state.
//
// Markup: ../../styleguide/src/components/field_radio.hbs
//
// Weight: 5
//
// Style guide: forms.input-radio

.radio {
  [type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  label {
    position: relative;
    padding-left: calc(var(--radio-size) * 1.4);

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      margin-right: 6px;
      width: var(--radio-size);
      height: var(--radio-size);
      border: none;
      background-color: var(--checkbox-radio-color);
      background-position: top center;
      mask-image: svg-url('<svg viewBox="0 0 21.75 21.75" xmlns="http://www.w3.org/2000/svg"><circle cx="10.88" cy="10.88" r="10.38" fill="transparent" vector-effect="non-scaling-stroke" stroke="currentColor"/></svg>');
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
      vertical-align: top;
      content: '';
    }
  }

  [type='radio']:checked + label:before,
  label.is-checked:before {
    // background, checked state
    mask-image: svg-url('<svg viewBox="0 0 21.75 21.75" id="radio_selected" xmlns="http://www.w3.org/2000/svg"><circle cx="10.88" cy="10.88" r="10.38" fill="transparent" vector-effect="non-scaling-stroke" stroke="currentColor"/><circle cx="10.88" cy="10.88" r="7.34" fill="currentColor"/></svg>');
    background-color: var(--checkbox-radio-selected-color);
  }
}

// Field rows
//
// Fields can be placed side-by-side on large screens by using these container
// classes. A wrapper with `.field-row` by itself will shrink the `.field` items
// to space them evenly, with a minimum of `200px` wide. In practice, this means
// that with an 800px wide container, either two or three fields will work
// side-by-side on desktop sizes
// without any extra classes.
//
// The `.priority` modifier class is designed to show two fields side-by-side
// where the first field has priority over the second. This is useful when the
// second field adds details to the first. If this is to be used anywhere on a
// form, all rows should be wrapped in `<div class="field-row priority">`
// containers in order to make the first column evenly spaced all the way down
// the page.
//
// .priority - Give prominance to the first field in the row to show primary and secondary infomation.
// .full - Override grid to make `.field` items full-width
// .compact - Shrink the first column to the size of its contents, with a min-width of 160px.
// .inline-fields - Make fields inline using flexbox instead of grid. This is best used in combination with `.inline-label` on `.field` elements.
// .narrow - Changes max-width from 200px to 160px, which allows for thirds in the default column size for forms
// .inline-fields.pill-labels - alternate display for radio and checkbox groups
//
// Markup: ../../styleguide/src/components/field_row.hbs
//
// Weight: 10
//
// Style guide: forms.field-rows

.field-row {
  --max-field-width: none;
  display: grid;
  grid-gap: 0 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  &.full {
    display: block; // override grid
  }

  &.compact {
    grid-template-columns: minmax(160px, min-content) 1fr;
    grid-gap: 0 40px;
  }

  &.narrow {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }

  @include breakpoint($smedium) {
    &.priority {
      grid-template-columns: 5fr 4fr;
    }

    &.first-item-priority {
      > *:first-child {
        grid-column: 1 / -1;
      }
    }


  }

  &.inline-fields {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--global-margin);
    gap: rem-calc(8);

    .field {
      margin: 0;
    }
  }

  &.pill-labels {
    gap: 0;

    .field {
      margin: 0 8px 8px 0;
    }

    .radio,
    .checkbox {
      label {
        background-color: var(--card-bg-shade);
        border-radius: var(--global-radius);
        padding: 8px 12px;
        margin: 0;

        &:before {
          top: 9px;
          left: 9px;
        }
      }

      [type="radio"]:checked + label,
      [type="checkbox"]:checked + label {
        background-color: var(--info-color-tint4);
      }

      [type="radio"]:checked + label:after {
        top: 11px;
        left: 11px;
      }

      [type="checkbox"]:checked + label:after {
        top: 13px;
        left: 13px;
      }
    }

    .radio {
      label {
        padding: 8px 12px 8px calc(var(--radio-size) * 1.8);
      }
    }
  }
}

// See https://wharton-interactive.gitlab.io/js/wi/styleguide/item-forms-manage-settings.html
.subfield {
  display: flex;
  align-items: flex-start;

  .field {
    margin: 0 0 0 rem-calc(10);
  }

  &.radio .field,
  &.checkbox .field {
    // align text fields with radios
    margin-top: rem-calc(-14);
  }
}

// Nested Checkbox and radio examples
//
// In some frontend frameworks, IDs on form fields would require unwanted
// dependencies. In those cases, checkboxes and radio groups can be built to
// use modifier classes to manage state.
//
// .is-checked - Add this to the parent field label when the option is selected.
//
// Markup: ../../styleguide/src/components/forms_nested_checkbox_examples.hbs
//
// Weight: 20
//
// Style guide: forms.nested_checkbox_radios

// Help Text
//
// Help text can be added under the field with `<p class="help-text">` Help text
// should be be tied to the form field using the aria-describedby attribute and
// an ID. This will ensure that screen readers—will announce the help text when
// the user focuses the field.
//
//
// Markup: ../../styleguide/src/components/field_help_text.hbs
//
// Weight: 22
//
// Style guide: forms.help-text

.help-text,
.helptext {
  display: block;
  margin: 4px 0 8px;
  font-size: var(--label-font-size);
  font-style: italic;

  ul {
    font-style: normal;
  }
}

// Error text
//
// Error text can either be injected when needed or hidden until the `.error`
// class is added to `.field`.
//
// Markup: ../../styleguide/src/components/field_error.hbs
//
// Weight: 23
//
// Style guide: forms.error-text

.error-message,
.errorlist {
  margin: 4px 0 8px;
  font-size: var(--label-font-size);
  color: var(--error-color);
}

// Class Information Example Form
//
// Markup: ../../styleguide/src/components/class-form.hbs
//
// Weight: 10
//
// chromeless: true
//
// full: true
//
// Style guide: forms.class-form

// Manage Settings Example
//
// Markup: ../../styleguide/src/components/settings-form.hbs
//
// Weight: 11
//
// chromeless: true
//
// full: true
//
// Style guide: forms.manage-settings


.reporting-description {
  max-width: 600px;
  padding: rem-calc(40) 0;
}

.highcharts-container {
  min-width: 100%;
  height: 55vh;
}


.choice-sets {

}

.choice-set {
  &-label {
    font-weight: var(--global-weight-semibold);
    font-size: var(--font-size-base);
  }

  @include breakpoint($medium) {
    &-grid {
      display: flex;
      gap: 1rem;

      .field,
      .choice-set-item {
        max-width: 100%;
        flex: 1 1 100%;
      }
    }
  }
}

.cs-option {
  &-label {
    border: 1px solid var(--info-color-tint4);
    border-radius: var(--global-radius);
    background-color: var(--info-color-tint5);
    padding: 1rem;

    &.cs-option-label {
      padding-left: 1rem;

      &:before {
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
    }

    @at-root [type='radio']:checked + & {
      border: 1px solid var(--info-color);
      background-color: var(--card-bg);
      box-shadow: var(--base-shadow);
    }

    @at-root .field:not(.toggle):focus-within [type='radio'] + &:after {
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      width: var(--radio-size);
      height: var(--radio-size);
    }

  }

  &-title {
    display: block;
    font-weight: var(--global-weight-semibold);
    font-size: var(--font-size-base);
    margin-bottom: 0.5rem;
  }

  &-row {
    display: flex;
    align-items: baseline;
    gap: 8px;
    line-height: 1.2;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-dt {
    flex: 1 1 180px;
    padding-left: 2rem;
    font-size: rem-calc(12);

    @at-root .no-radios & {
      padding-left: 0;
    }
  }

  &-dd {
    flex: 1 1 100%;
    font-size: rem-calc(18);
  }
}


.option-set {
  &-row {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    > .option-set-label {
      flex: 0 1 130px;

      &.align-end {
        align-self: flex-end;
      }
    }

    > * {
      flex: 1 1 calc(50% - 2rem - 75px);
      margin: 0;
    }
  }
}

.choices__item {
  font-weight: var(--global-weight-semibold);

  .options {
    display: block;
    font-size: 0.9em;
    font-weight: var(--global-weight-normal);
  }
}

[data-fieldset-item] + [data-fieldset-item] + [data-fieldset-item] {
  margin-top: 1.5rem;
}
