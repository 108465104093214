// Vertical Nav
//
// Markup: ../../styleguide/src/components/vertical-nav.hbs
//
// chromeless: true
//
// full: true
//
// Weight: 60
//
// Style guide: no styleguide reference

.v-nav {
  --icon-color: var(--v-nav-color);
  --icon-size: #{rem-calc(16)};
  position: fixed;
  width: 60px;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: var(--v-nav-bg, var(--brand-color));
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: $z-index-sticky-header;

  @at-root .simpl-header + & {
    height: calc(100vh - var(--site-nav-height));
    top: var(--site-nav-height);
    bottom: 0;
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    &.is-bottom {
      margin-top: auto;
    }
  }

  &-item:first-child {
    .v-nav-link {
      padding-top: rem-calc(20);
    }
  }

  &-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: rem-calc(12) rem-calc(8) rem-calc(10);
    color: var(--v-nav-color);
    font-size: rem-calc(11);
    text-decoration: none;

    &[disabled] {
      opacity: 0.3;
      cursor: default;
    }

    &.is-highlighted {
      --icon-color: var(--alert-color-tint2);
      color: var(--alert-color-tint2);
    }

    &:not([disabled]):not([aria-current='page']):hover {
      background: rgba(1255, 255, 255, 0.05);
    }

    &[aria-current='page'] {
      background: var(--info-color-tint1);
    }
  }

  &-text {
    display: block;
    text-align: center;
    margin-top: rem-calc(8);
  }

  &-logo {
    padding: rem-calc(9) rem-calc(12) rem-calc(10);
    display: block;
  }

  &-content {
    padding-left: 60px;
  }

  @include breakpoint($nav-breakpoint) {
    --icon-size: #{rem-calc(24)};
    width: 80px;

    &-content {
      padding-left: 80px;
    }
  }
}
